<template>
  <div class="relative">
    <p class="text-xl font-headers mb-1.5 font-bold text-grey-dark-2">
      Job Roles
    </p>
    <JobRolesTable />
  </div>
</template>
<script>
import JobRolesTable from "../components/JobRolesTable";

export default {
  name: "ProductJobRoles",
  components: { JobRolesTable },
};
</script>
