<template>
  <div class="bg-white shadow p-3 mb-6.5 rounded">
    <div class="absolute top-0 right-0 gap-2 flex">
      <Button
        text="Add New"
        type="primary-white"
        size="medium"
        @click.native="addNewJobRole"
      />
      <Button
        text="Save and Continue"
        type="primary"
        size="medium"
        @click.native="copyJobRoles"
        :disabled="selectedJobRolesLength === 0"
      />
    </div>
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
      Platform Job Roles
    </p>
    <p class="text-mdh font-normal text-grey-dark-1 max-w-3/4">
      To create a new job role select Add New.
    </p>
    <p class="text-mdh font-normal text-grey-dark-1 max-w-3/4">
      To create a similar job role structure select the job role you wish to
      clone and select Save and Continue.
    </p>
    <p class="text-mdh font-normal text-grey-dark-1 max-w-3/4 mb-1.5">
      Once you save and continue you will be able to edit the job role
      information and targets.
    </p>
    <div class="flex flex-row items-end justify-between">
      <div>
        <InputField
          placeholder="Search by name"
          label="Filter by"
          :onChange="setKeyword"
          leftIcon
          class="mb-2 w-inputField"
          data-testid="name-field"
        />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} Job Roles
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-1.5 rounded">
        <button
          class="w-60 p-0.4 gap-0.4 items-center justify-center flex"
          @click="toggleAll"
        >
          <Checkbox :active="selectAll" />
        </button>
        <div
          class="flex flex-row items-center justify-center focus:outline-none w-75 ml-1 pr-1"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow w-1/4 mr-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            data-testid="header-1"
            @click="() => sort('jobrolename', 1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/3 items-center">
          <button
            class="flex flex-row items-center focus:outline-none"
            data-testid="header-1"
            @click="() => sort('jobTitleMapping', 2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE MAPPING</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/4 justify-center items-center">
          <button 
            class="flex flex-row items-center focus:outline-none" 
            data-testid="header-1"
            @click="() => sort('assessment', 3)"
            >
            <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT</p>
            <img 
              src="../../../../assets/img/delta-table/caret.svg" 
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" 
              />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 700px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";
import VirtualList from "vue-virtual-scroll-list";

import ListItem from "./ListItem.vue";
import Checkbox from "../../../../components/Checkbox";

export default {
  name: "JobRolesTable",
  components: { VirtualList, Checkbox },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectAll: false,
  }),
  computed: {
    ...mapState({
      jobRoles: (state) => state.assessmentSetup.jobRolesDescriptions,
      productDetails: (state) => state.products.productDetails,
    }),
    selectedJobRolesLength() {
      return this.mappedList.filter((item) => item.isSelected).length;
    },
    selectedJobRolesIds() {
      return this.mappedList
        .filter((item) => item.isSelected)
        .map((item) => item.id);
    },
  },
  watch: {
    keyword() {
      this.mappedList = [];
      this.selectAll = false;
      this.getJobRoles(this.keyword);
    },
    selectedJobRolesLength(value) {
      return value === this.mappedList.length && value !== 0
        ? (this.selectAll = true)
        : (this.selectAll = false);
    },
  },
  methods: {
    ...mapActions({
      getJobRolesDescription: "assessmentSetup/getJobRolesDescription",
      openModal: "modals/openModal",
      resetJobRoleDetails: "products/resetJobRoleDetails",
    }),
    copyJobRoles() {
      const id = this.productDetails.id;
      const payload = this.selectedJobRolesIds;
      this.openModal({
        modal: "copyJobRolesModal",
        props: { id, payload },
      });
    },
    addNewJobRole() {
      this.resetJobRoleDetails();
      this.$router.push("job-role/general-information");
    },
    async getJobRoles(keyword = "") {
      await this.getJobRolesDescription({ pageNr: 1, pageSz: 999, keyword });
      this.copyToLocal();
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    copyToLocal() {
      this.mappedList = this.jobRoles
        .filter((item) => item.isActive)
        .map((item) => ({ ...item, isSelected: false }));
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
    toggleItem(id) {
      this.mappedList.map((item) =>
        item.id === id ? (item.isSelected = !item.isSelected) : null
      );
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "number":
          this.mappedList.reverse();
          break;
        case "jobrolename":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "jobTitleMapping":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.jobTitles.localeCompare(b.jobTitles)
          );
          break;
        case "assessment":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.attachedSurveyNames?.[0]?.localeCompare(b.attachedSurveyNames?.[0])
          );
          break;
        default:
          break;
      }
    },
  },
  async mounted() {
    await this.getJobRoles();
  },
};
</script>
