<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-1.5 items-center">
      <button
        class="flex w-60 p-0.2 justify-center gap-0.4"
        @click="() => toggle(source.id)"
      >
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-75 justify-center mr-2 pr-1">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4 mr-1 pl-0.5">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.name }}
        </p>
      </div>
      <div class="flex flex-grow w-1/3 pl-0.5">
        <p class="text-md text-grey-dark-2">
          <ExpandMore :data="jobTitles" showHideButton />
        </p>
      </div>
      <div class="flex flex-grow flex-col w-1/4 justify-center items-center">
        <p class="text-md text-grey-dark-2" v-for="survey in surveys">
          {{ survey }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import Checkbox from "../../../../components/Checkbox";
import ExpandMore from "../../../../components/ExpandMore/ExpandMore.vue";

export default {
  name: "ListItem",
  components: { Checkbox, ExpandMore },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
  },
  computed: {
    jobTitles() {
      return this.source.jobTitles.split(",");
    },
    surveys() {
      if (!this.source.attachedSurveyNames?.length)
        return ["No Assessment attached"];
      return this.source.attachedSurveyNames;
    },
  },
};
</script>
